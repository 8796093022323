import clsx from "clsx";
import React, { useState } from "react";

import { globalCodeList } from './globals';

const WRAPPER_STYLES = clsx(
  "text-center",
  "flex",
  "flex-col",
  "flex-center",
  "grow",
  "px-16",
);

export default function AvailableCodeInputPage(props: {
  then: () => void,
}) {
  const {then} = props;
  const curCodeList = globalCodeList.allAvailableCodes();
  const [codeListInput, setCodeListInput] = useState(curCodeList.join("\n"));

  const cancel = () => {
    setCodeListInput(globalCodeList.allAvailableCodes().join("\n"))
    then();
  }

  const save = () => {
    let values: string[];
    try {
      values = codeListInput.split(/[\n,]+/u);
      values = values.map(value => value.trim());
    } catch (err) {
      console.error("Codes input parse error", err);
      throw err;
    }
    globalCodeList.putCodes(values);
    setCodeListInput(globalCodeList.allAvailableCodes().join("\n"))
    then();
  }

  return (
    <div className={WRAPPER_STYLES}>
      <p>You can add more codes here, one per line or comma-separated.</p>
      <textarea
        className={clsx(
          "grow",
          "bg-black",
          "p-2",
          "mt-4",
          "overflow-y-scroll",
          "border",
          "rounded",
          "border-gray-600",
          "font-mono",
        )}
        onChange={(event) => setCodeListInput(event.target.value)}
        value={codeListInput}
      />
      <div className={clsx(
        "my-4",
        "flex",
        "flex-row",
        "justify-between",
      )}>
        <button
          className={clsx(
            "rounded-md",
            "bg-black",
            "px-3",
            "py-2",
            "hover:bg-gray-900",
            "focus-visible:outline",
            "focus-visible:outline-2",
            "focus-visible:outline-offset-2",
            "focus-visible:outline-emerald-50",
          )}
          onClick={cancel}
          value="Cancel"
        >
          Cancel
        </button>
        <button
          className={clsx(
            "rounded-md",
            "bg-emerald-600",
            "px-3",
            "py-2",
            "text-sm",
            "font-semibold",
            "text-white",
            "shadow-sm",
            "hover:bg-emerald-700",
            "focus-visible:outline",
            "focus-visible:outline-2",
            "focus-visible:outline-offset-2",
            "focus-visible:outline-emerald-50",
          )}
          onClick={save}
          value="Save"
        >
          Save
        </button>
      </div>
    </div>
  );

}
