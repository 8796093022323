
function dateJsonHandler(key: string, value: any) {
  if (typeof value === 'string') {
    // 2024-08-17T00:17:18.310Z
    if (value.match(/^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d{0,3})?Z$/)) {
      return new Date(Date.parse(value));
    }
  }
  return value;
}

export default class CodeList {

  protected key: string;
  // Active code is still in the `availableCodeList`. It should only ever refer
  // to the FIRST item in the list (element 0)
  protected activeCode: string | null;
  protected availableCodes: string[];
  protected usedCodes: {[code: string]: Date};
  protected listener?: (data: any) => void;

  constructor(key: string) {
    this.key = key;
    this.activeCode = null;
    this.availableCodes = [];
    this.usedCodes = {};
  }

  putCodes(newCodes: string[]): void {
    newCodes = Array.from(new Set(newCodes));  // De-dupe
    newCodes = newCodes.filter(code => code && code !== "");  // Remove empties
    newCodes = newCodes.filter(code => !this.usedCodes[code]);  // Prevent re-using codes
    this.availableCodes = newCodes;
    this.handleChange();
  }

  addCode(newCode: string): void {
    if (this.usedCodes[newCode]) {
      throw new Error(`The code "${newCode}" has already been used`);
    }
    // add the code if it doesn't already exist. If it _does_ exist, but just
    // hasn't been redeemed yet, silently do nothing.
    if (this.availableCodes.indexOf(newCode) < 0) {
      this.availableCodes.push(newCode);
      this.handleChange();
    } else {
      console.info(`Code already on list: ${newCode}`);
    }
  }

  /** Updates the current code to the next one and returns it.
   *
   * If the current code hasn't been flagged as used yet, flag it.
   *
   * Currently this is LIFO, which should be fine, but is maybe a little weird
   * that it'll show the most recently added code first.
   */
  nextCode(): string | null {
    if (this.activeCode) {
      this.markCodeUsed();
    }
    if (this.availableCodes.length) {
      const nextCode = this.availableCodes[0];
      this.activeCode = nextCode;
      this.handleChange();
      return nextCode
    } else {
      this.activeCode = null;
      this.handleChange();
      return null;
    }
  }

  /**
   * Marks the active code as `used` and removes it from the active list
   */
  markCodeUsed(): void {
    if (this.activeCode) {
      this.usedCodes[this.activeCode] = new Date();
      this.availableCodes.shift();
      this.activeCode = null;
      this.handleChange();
    } else {
      console.info("Attempted to mark code as used, but none are active");
    }
  }

  allAvailableCodes(): string[] {
    return this.availableCodes;
  }

  remainingCount(): number {
    if (this.activeCode) {
      return this.availableCodes.length - 1;
    }
    return this.availableCodes.length;
  }

  allUsedCodes(): {usedDate: Date, code: string}[] {
    const usedCodes = Object.entries(this.usedCodes).map(([key, value]) => {
      return {code: key, usedDate: value};
    });
    usedCodes.sort((a, b) => a.usedDate.getTime() - b.usedDate.getTime());
    usedCodes.reverse();
    return usedCodes;
  }

  saveToStorage(): void {
    const fullData = {
      availableCodes: [...this.availableCodes],
      usedCodes: {...this.usedCodes},
    }
    const fullDataJson = JSON.stringify(fullData);
    localStorage.setItem(`CodeList__${this.key}`, fullDataJson);
  }

  loadFromStorage(): void {
    const fullDataJson = localStorage.getItem(`CodeList__${this.key}`);
    if (fullDataJson) {
      const fullData = JSON.parse(fullDataJson, dateJsonHandler);
      this.availableCodes = [...this.availableCodes, ...fullData.availableCodes];
      this.usedCodes = {...this.usedCodes, ...fullData.usedCodes};
      this.handleChange();
    }
  }

  listen(callback?: (data: any) => void): void {
    if (callback) {
      this.listener = callback;
      this.handleChange();
    } else{
      this.listener = undefined;
    }
  }

  handleChange() {
    this.saveToStorage();
    this.notify();
  }

  notify() {
    if (this.listener) {
      this.sendState(this.listener);
    }
  }

  // Should only be called on change - this creates a new object each time
  protected sendState(callback: (data: any) => void) {
    callback({
      activeCode: this.activeCode,
      availableCodes: [...this.availableCodes],
      usedCodes: this.allUsedCodes(),
      remainingCount: this.remainingCount(),
    });
  }

}
