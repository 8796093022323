import clsx from "clsx";
import { ReactElement, useEffect, useState } from "react";
import { globalCodeList } from "./globals";



const INITIAL_STATE = Object.freeze({
  activeCode: null,
  availableCodes: [],
  usedCodes: {},
  remainingCount: 0,
});

const WRAPPER_STYLES = clsx(
  "text-center",
  "flex",
  "flex-col",
  "flex-center",
);

const CODE_TEXT_STYLES = clsx(
  "text-center",
  "my-4",
  "text-2xl",
);

const SUB_TEXT_STYLES = clsx(
  "text-center",
  "text-sm",
  "text-gray-300",
);

const INFO_TEXT_STYLES = clsx(
  "text-center",
  "text-m",
);

const BUTTON_STYLES = clsx(
  "mx-20",
  "my-2",
  "rounded-md",
  "bg-emerald-600",
  "px-3",
  "py-2",
  "text-sm",
  "font-semibold",
  "text-white",
  "shadow-sm",
  "hover:bg-emerald-700",
  "focus-visible:outline",
  "focus-visible:outline-2",
  "focus-visible:outline-offset-2",
  "focus-visible:outline-indigo-50",
);

const BUTTON_STYLES_DISABLED = clsx(
  "mx-20",
  "my-2",
  "rounded-md",
  "text-gray-500",
  "bg-gray-700",
  "px-3",
  "py-2",
  "text-sm",
  "font-semibold",
  "text-white",
  "shadow-sm",
);

export default function CodeDisplayPage(props: {}) {

  const [codeList, setCodeList] = useState(INITIAL_STATE);

  // Set up the listener when the component loads
  useEffect(() => {
    globalCodeList.listen(setCodeList);
  }, []);

  return (
    <CodeOutput
      activeCode={codeList.activeCode}
      availableCodes={codeList.availableCodes}
      remainingCount={codeList.remainingCount}
    />
  );
}

function CodeOutput(props: {
  activeCode: string | null,
  availableCodes: string[],
  remainingCount: number,
}): ReactElement {
  const {
    activeCode,
    availableCodes,
    remainingCount,
  } = props;
  if (activeCode) {
    return (
      <div
        className={WRAPPER_STYLES}
      >
        <p className={CODE_TEXT_STYLES}>{activeCode}</p>
        <button className={BUTTON_STYLES} onClick={() => globalCodeList.nextCode()}>
          Next Code
        </button>
        <p className={SUB_TEXT_STYLES}>{remainingCount} {remainingCount === 1 ? "code" : "codes"} left</p>
      </div>
    );
  } else if (availableCodes.length) {
    return (
      <div
        className={WRAPPER_STYLES}
      >
        <p className={CODE_TEXT_STYLES}>&nbsp;</p>
        <button className={BUTTON_STYLES} onClick={() => globalCodeList.nextCode()}>Next Code</button>
        <p className={SUB_TEXT_STYLES}>{remainingCount} {remainingCount === 1 ? "code" : "codes"} left</p>
      </div>
    );
  }
    return (
      <div
        className={WRAPPER_STYLES}
      >
        <p className={CODE_TEXT_STYLES}>&nbsp;</p>
        <button className={BUTTON_STYLES_DISABLED} disabled={true}>Next Code</button>
        <p className={INFO_TEXT_STYLES}>There are no more codes available.<br/>Use "Manage Codes" to add more.</p>
    </div>
  );
}
