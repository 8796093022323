import CodeList from "./CodeList";



// Load up the global list of codes
export const globalCodeList = new CodeList("global");
globalCodeList.loadFromStorage();

// Give us a custom namespace in `window`
//
// See: https://stackoverflow.com/a/12709880/703040
declare global {
    interface Window {
      globals: any;
    }
}

window.globals = {
  codeList: globalCodeList,
};
