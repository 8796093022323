import clsx from "clsx";
import moment from "moment";
import { useState } from "react";
import { globalCodeList } from "./globals";

const WRAPPER_STYLES = clsx(
  "text-center",
  "flex",
  "flex-col",
  "flex-center",
  "overflow-auto",
  "overflow-y-scroll",
);

export default function CodeHistoryPage() {

  const [usedCodes, setUsedCodes] = useState(globalCodeList.allUsedCodes());

  return (
    <div className={WRAPPER_STYLES}>
      <p className={clsx("flex-none")}>The following codes have been used:</p>
      <div
          className={clsx(
            "flex-auto",
            "mt-4",
            "overflow-y-scroll",
            "border",
            "rounded",
            "border-gray-600",
            "font-mono",
            "h-full",
          )}
        >
        <table
          className={clsx(
            "w-full",
          )}
        >
          <thead>
            <th className={clsx("text-left", "py-1", "px-4")}>Code</th>
            <th className={clsx("text-right", "py-1", "px-4")}>Used</th>
          </thead>
          <tbody>
            {usedCodes.map(({code, usedDate}) => (
              <tr>
                <td className={clsx("text-left", "py-1", "px-4")}>{code}</td>
                <td className={clsx("text-right", "py-1", "px-4")}>{moment(usedDate).format("M/D h:mm a")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

