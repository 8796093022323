import clsx from 'clsx';
import React, { useState } from 'react';

import './App.css';

import AvailableCodeInputPage from './AvailableCodeInputPage';
import CodeDisplayPage from './CodeDisplayPage';
import CodeHistoryPage from './CodeHistoryPage';

const VIEW_SINGLE_CODE = "VIEW_SINGLE_CODE";
const VIEW_CODE_LIST = "VIEW_CODE_LIST";
const VIEW_CODE_HISTORY = "VIEW_CODE_HISTORY";

const NAV_BUTTON_STYLES = clsx(
  "text-sm",
  "font-semibold",
  "leading-6",
  "text-gray-300",
);

const NAV_BAR_STYLES = clsx(
  "flex-none",
  "flex",
  "flex-row",
  "justify-between",
  "py-4",
  "px-10",
);

function App() {

  const [curView, setCurView]: [string, any] = useState(VIEW_SINGLE_CODE);

  const afterCodeListSubmit = () => {
    setCurView(VIEW_SINGLE_CODE);
  }

  return (
    <div
      className={clsx(
        "bg-black",
        "text-gray-50",
        "flex",
        "flex-row",
        "flex-items-center",
        "justify-center",
        "h-full",
        "w-full",
      )}
    >
      <div
        className={clsx(
          "h-full",
          "flex",
          "flex-col",
          "flex-items-center",
          "justify-between",
        )}
      >
        <header
          className={clsx(
            "flex-none",
          )}
        >
          <img
            src="Nextworld-Title-Logo-red.png"
            className={clsx(
              "max-h-80",
            )}
          />
        </header>
        {(curView === VIEW_SINGLE_CODE) && (
          <React.Fragment>
            <CodeDisplayPage />
            <div
              className={NAV_BAR_STYLES}
            >
              <button className={NAV_BUTTON_STYLES} onClick={() => setCurView(VIEW_CODE_LIST)}>Manage Codes</button>
              <button className={NAV_BUTTON_STYLES} onClick={() => setCurView(VIEW_CODE_HISTORY)}>History</button>
            </div>
          </React.Fragment>
        )}
        {(curView === VIEW_CODE_LIST) && (
          <React.Fragment>
            <AvailableCodeInputPage then={afterCodeListSubmit}/>
          </React.Fragment>
        )}
        {(curView === VIEW_CODE_HISTORY) && (
          <React.Fragment>
            <CodeHistoryPage />
            <div
              className={NAV_BAR_STYLES}
            >
              <button className={NAV_BUTTON_STYLES} onClick={() => setCurView(VIEW_SINGLE_CODE)}>&lt; Back</button>
              <div>&nbsp;</div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default App;
